import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const Navbar= styled.nav`
  height: 5vh;
  display: flex;
  justify-content: flex-end;
   align-items: center;
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #555;
  background-blend-mode: multiply,multiply;
  opacity: 0.85;
  transition: all 200ms ease;
  position: relative;
  text-transform: uppercase;
  border-bottom: 2px solid #33333320;
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 100;
  align-self: center;

  &:hover {
	  opacity: 1
  }

  @media (max-width: ${breakpoints.m}px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }

  @media (max-width: ${breakpoints.s}px){
    height: 6vh;
  }
`
export const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: ${breakpoints.m}px) {
    display: flex;
  }
`
export const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  
  & a {
    display: inline-block;
    white-space: nowrap;
    margin: 0 1vw;
    position: relative;
	  font-family: 'Open Sans', sans-serif;
    font-size: 1.675rem;
    color: #fff;
    text-transform: uppercase;
    transition: all 200ms ease;

    &:hover {
      color: pink;
    }
  }
  
  @media (max-width: ${breakpoints.l}px){
    & a {
      font-size: 1.5rem;
    }
  }

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #555;
    background-blend-mode: multiply,multiply;
    opacity: 0.85;
    transition: all 0.3s ease-in;
    top: 5vh;
    left: ${props => (props.open ? "-100%" : "0")};
	  z-index: 6;
	
    & a {
      padding-bottom: 20px;
      font-weight: 600;
    }
  }
  @media (max-width: ${breakpoints.s}px){
    top: 6vh;
  }
`
export const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
export const Logo = styled.div`
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: ${breakpoints.xs}px){
    padding-left: 0;
    padding-right: 0;
  }
`
export const NavLogo = styled.div`
  margin-right: auto;

  @media (max-width: ${breakpoints.m}px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
`
export const NavBag = styled.div`
  margin-left: 2rem;
`

export const NavLink = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  transition: all 200ms ease;

  &:hover {
    color: pink;
  }
  
`
export const MenuLink = styled.div`
  & a {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    font-size: 1.825rem;

    &:hover {
      color: pink;
    }

    @media (max-width: ${breakpoints.m}px){
      font-size: 1.725rem;
    }

    @media (max-width: ${breakpoints.s}px){
      font-size: 1.375rem;
    }

    @media (max-width: ${breakpoints.xs}px){
      font-size: 1.0375rem;
    }
  }
`

export const CartCounter = styled.span`
  position: absolute;
  top: 17px;
  left: -11px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: pink;
  text-align: center;
  line-height: 23px;
  font-size: 12px;
  font-weight: 600;
  color: #4a4a4a;
  z-index: 1;
`
