import React, { useState, useContext} from "react"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import reduce from 'lodash/reduce'
import Bag from '~/components/Image/bag'
import {Navbar, Toggle, NavBag, CartCounter, 
  MenuLink, NavLogo, Navbox, Hamburger, Logo,
} from './styles'
import StoreContext from '~/context/StoreContext'


const useQuantity = () => {
	const {store: {checkout}} = useContext(StoreContext)
	const items = checkout ? checkout.lineItems : []
	const total = reduce(items, (acc, item) => acc + item.quantity, 0)
	return [total !== 0, total]
}

const Navigation = () => {
	const [hasItems, quantity] = useQuantity()
	const [navbarOpen, setNavbarOpen] = useState(false)
	const divStyle = {
		width: '26px',
		height: '32px'
	}

  return (
    <Navbar>
      <NavLogo>
        <Logo>
          <MenuLink>
            <AniLink 
                cover to="/"
                direction="up"
                bg="pink">Bags-2-Slay
            </AniLink>
          </MenuLink>
        </Logo>
      </NavLogo>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>

      {navbarOpen ? (
        <Navbox>
            <AniLink 
              cover to="/"
              direction="up"
              bg="pink">Home
            </AniLink>
            <AniLink 
              cover to="/products"
              direction="down"
              bg="pink">Bags
            </AniLink>
            <AniLink 
              cover to="#"
              direction="down"
              bg="pink">Blog
            </AniLink>
            <AniLink 
              cover to="/contact"
              direction="down"
              bg="pink">Contact
            </AniLink>
        </Navbox>
      ) : (
        <Navbox open>
			<AniLink 
			  cover to="/"
			  direction="right"
			  bg="pink">Home
			</AniLink>
			<AniLink 
			  cover to="/products"
			  direction="left"
			  bg="pink">Bags
			</AniLink>
			<AniLink 
			  cover to="#"
			  direction="left"
			  bg="pink">Blog
			</AniLink>
			<AniLink 
			  cover to="/contact"
			  direction="left"
			  bg="pink">Contact
			</AniLink>
        </Navbox>
      )}
        <NavBag>
          {hasItems &&
          <CartCounter>
            {quantity}
          </CartCounter>
          }<div style={divStyle}>
            <MenuLink>
              <AniLink 
                paintDrip to='/cart' 
                color='pink'>
                  <Bag />
              </AniLink>
            </MenuLink>
            </div> 
        </NavBag>
      </Navbar>
  )
}

export default Navigation
