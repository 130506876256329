import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const Bag = () => {
    const {bag} = useStaticQuery(graphql`
        query {
            bag: file(relativePath: {eq: "bag.png"}, sourceInstanceName: {eq: "images"}){
                childImageSharp {
                    fixed (width: 26) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        } 
        `
    )
    return <Img fixed={bag.childImageSharp.fixed} />
}

export default Bag