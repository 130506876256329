import styled from '@emotion/styled'
import {breakpoints} from '~/utils/styles'

export const Footer = styled.div`
    background: #f6f8fa;
	margin-top: 20px;
`
export const FooterContent = styled.div`
    padding-top: 84px;
    padding-bottom: 64px;
`
export const SectionContainer = styled.div`
    padding-left: 1%;
    padding-right: 1%;
`
export const FooterContainer = styled.div`
    max-width: 100%;

    @media (max-width: ${breakpoints.l}px){
        max-width: 720px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: ${breakpoints.m}px){
        max-width: 540px;
    }

    @media (max-width: ${breakpoints.s}px){
        max-width: 100%;
    }
`
export const FooterCol = styled.div`
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

    &(:nth-last-child(n+3)){
        margin-bottom: 80px;
    }

    @media (max-width: ${breakpoints.xxl}px){
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media (max-width: ${breakpoints.l}px){
        flex: 0 0 50%;
        max-width: 50%;
    }

    @media (max-width: ${breakpoints.m}px){
        &(:nth-last-child(n+3)){
            margin-bottom: 0px;
        }
        &:not(:last-child){
            margin-bottom: 80px;
        }
    }
`
export const FooterAboutText = styled.div`
    padding-right: 50px;
`
export const FooterUpper = styled.div`
    margin-top: 8px;
`
export const FooterTitle = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #414141;
    text-transform: uppercase;
`
export const FooterLogo = styled.div`
    width: 100%;
    & img {
        width: 120px;
        height: auto;
    }
`

export const FooterList = styled.div`
    margin-top: 45px;
    & a {
        color: #3a3a3a;
    }

    & ul {
        columns: 2;
    }
    & ul li {
        margin-right: 10px;
    }
    & ul li:not(:last-child){
        margin-bottom: 9px;
    }
`
export const FooterBlogContainer = styled.div`
    margin-top: 92px;
`
export const FooterBlogItem = styled.div`
    &:not(:last-child){
        margin-bottom: 30px;
    }
`
export const FooterBlogImage = styled.div`
    width: 68px;
    height: 68px;
`
export const FooterBlogContent = styled.div`
    padding-left: 29px;
`
export const FooterContactList = styled.div`
    margin-top: 45px;
    padding-right: 20px;
    & ul li:not(:last-child){
        margin-bottom: 19px;
    }

    & ul li span {
        font-size: 14px;
        font-weight: 700;
        color: #bbe432;
    }

    & ul li div {
        padding-left: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #8f8f8f;
        line-height: 1.75;
        margin-top: -1px;
    }
`
export const Cards = styled.div`
    margin-top: 50px;
    & ul li:not(:last-child){
        margin-right: 7px;
    }
`
export const FooterSocial = styled.div`
    width: 100%;
    background: #f0f5f7;
`
export const FooterSocialContainer = styled.div`
    & div {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
`
export const Credits = styled.div`
	width: 100%;
    background: #1a1a1a;
`
export const CreditsContainer = styled.div`
    color: #ddd;
    font-size: 12px;
    & div {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
`
export const FooterSocialItem = styled.div`
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
`
export const FooterSocialIcon = styled.div`
    font-size: 30px;
    color: #d7d7d7;
    transition: all 200ms ease;
    & i {
        color: #d7d7d7;
        transition: all 200ms ease;
    }
    &:hover i {
        color: #bbe432;
    }
	
`
export const FooterSocialTitle = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #9f9fa0;
    text-transform: uppercase;
    margin-left: 20px;
    transition: all 200ms ease;
    &:hover {
        color: #414141;
    }
	
	@media (max-width: ${breakpoints.s}px){
		display: none;
	}
`