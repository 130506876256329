import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {SectionContainer} from '~/utils/styles'
import {Footer, FooterContent, FooterAboutText, FooterUpper, 
    FooterTitle, FooterLogo, FooterList, FooterContactList, 
    Cards, FooterSocialContainer, FooterSocialItem, 
    FooterSocialIcon, FooterSocialTitle, Credits, FooterSocial,
    CreditsContainer
} from './styles'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faPinterestP, faTwitter
} from "@fortawesome/free-brands-svg-icons"
import card1 from '../../images/cards/card_1.jpg'
import card2 from '../../images/cards/card_2.jpg'
import card3 from '../../images/cards/card_3.jpg'
import card4 from '../../images/cards/card_4.jpg'
import card5 from '../../images/cards/card_5.jpg'
import logo3 from '../../images/logo3.jpeg'

const FooterComponent = () => {
    const commonStyle = `d-flex flex-row align-items-center justify-content-start`
    return (
            <Footer>
                <FooterContent>
                    <SectionContainer>
                        <div className={`container`}>
                            <div className={`row`}>
                                <div className={`col-xl-4`}>
                                    <div>
                                        <FooterLogo>
                                            <AniLink cover to="/" > 
                                                <img alt={`logo`} src={logo3} />
                                            </AniLink>
                                        </FooterLogo>
                                        <FooterAboutText>
                                        <p>Behind every successful woman is a fabulous handbag.</p>
                                        </FooterAboutText>
                                        <Cards>
                                            <ul className={`d-flex flex-row align-items-center justify-content-start`}>       
                                                <li><img alt={`credit debit card`} src={card1}/></li>
                                                <li><img alt={`credit debit card`} src={card2}/></li>
                                                <li><img alt={`credit debit card`} src={card3}/></li>
                                                <li><img alt={`credit debit card`} src={card4}/></li>
                                                <li><img alt={`credit debit card`} src={card5}/></li>
                                            </ul>
                                        </Cards>
                                    </div>
                                </div>
                                <div className={`col-xl-4`}>
                                    <FooterUpper>
                                        <FooterTitle>questions</FooterTitle>
                                        <FooterList>
                                            <ul>
                                                <li>
                                                    <AniLink to="#">About us</AniLink>
                                                </li>
                                                <li>
                                                    <AniLink to="#">Track Orders</AniLink>
                                                </li>
                                                <li>
                                                    <AniLink to="/legal/refundPolicy">Refund Policy</AniLink>
                                                </li>
                                                <li>
                                                    <AniLink to="#">Shipping</AniLink>
                                                </li>
                                                <li>
                                                    <AniLink to="#">Blog</AniLink>
                                                </li>
                                                <li>
                                                    <AniLink to="#">Partners</AniLink>
                                                </li>
                                                <li>
                                                    <AniLink to="/legal/termsOfService">Terms of Service</AniLink>
                                                </li>
                                                <li>
                                                    <AniLink to="/legal/privacyPolicy">Privacy Policy</AniLink>
                                                </li>
                                            </ul>
                                        </FooterList>
                                    </FooterUpper>
                                </div>
                                <div className={`col-xl-4`}>
                                    <FooterUpper>
                                        <FooterTitle>contact</FooterTitle>
                                        <FooterContactList>
                                            <ul>
                                                <li className={`d-flex flex-row align-items-start justify-content-start`}>
                                                    <span>C. </span>
                                                    <div>Bags-2-Slay, Inc.</div>
                                                </li>
                                                <li className={`d-flex flex-row align-items-start justify-content-start`}>
                                                    <span>A. </span>
                                                    <div>200 S Courtland St, PO Box 1267, East Stroudsburg, PA 18301</div>
                                                </li>
                                                <li className={`d-flex flex-row align-items-start justify-content-start`}>
                                                    <span>E. </span>
                                                    <div>bags2slay@gmail.com</div>
                                                </li>
                                            </ul>
                                        </FooterContactList>
                                    </FooterUpper>
                                </div>
                            </div>
                        </div>
                    </SectionContainer>
                </FooterContent>
                <FooterSocial>
                    <SectionContainer>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <FooterSocialContainer className={`d-flex flex-row align-items-center justify-content-between`}>
                                        <a href="https://instagram.com/bags2slay">
                                            <FooterSocialItem className={commonStyle}>
                                                <FooterSocialIcon><FontAwesomeIcon icon={faInstagram}/></FooterSocialIcon>
                                                <FooterSocialTitle>Instagram</FooterSocialTitle>
                                            </FooterSocialItem>
                                        </a>
                                        <a href="#">
                                            <FooterSocialItem className={commonStyle}>
                                                <FooterSocialIcon><FontAwesomeIcon icon={faPinterestP}/></FooterSocialIcon>
                                                <FooterSocialTitle>Pinterest</FooterSocialTitle>
                                            </FooterSocialItem>
                                        </a>
                                        <a href="#">
                                            <FooterSocialItem className={commonStyle}>
                                                <FooterSocialIcon><FontAwesomeIcon icon={faFacebookF}/></FooterSocialIcon>
                                                <FooterSocialTitle>Facebook</FooterSocialTitle>
                                            </FooterSocialItem>
                                        </a>
                                        <a href="#">
                                            <FooterSocialItem className={commonStyle}>
                                                <FooterSocialIcon><FontAwesomeIcon icon={faTwitter}/></FooterSocialIcon>
                                                <FooterSocialTitle>Twitter</FooterSocialTitle>
                                            </FooterSocialItem>
                                        </a>
                                    </FooterSocialContainer>
                                </div>
                            </div>
                        </div>
                    </SectionContainer>
                </FooterSocial>
                <Credits>
                    <SectionContainer>
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <CreditsContainer>
                                        Copyright &copy; {new Date().getFullYear()} by Bags-2-Slay, Inc. Template by <a href='https://colorlib.com' target='_blank' rel="noopener noreferrer">Colorlib</a>. Website by <a href='https://lloydmiller.tech' target='_blank' rel="noopener noreferrer">Johannes Labs, LLC</a>. 
                                    </CreditsContainer>
                                </div>
                            </div>
                        </div>
                    </SectionContainer>
                </Credits>
            </Footer>
    )
}

export default FooterComponent