import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from "@emotion/core"

export const breakpoints = {
  xs: 360,
  s: 576,
  m: 768,
  l: 992,
  xl: 1280,
  xxl: 1500
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      * {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
        text-shadow: rgba(0,0,0,.01) 0 0 1px;
      }
      body {
        margin: 0;
        overflow-x: hidden;
      }
      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
      div {
        display: block;
        position: relative;
        -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
      }
      ul {
        list-style: none;
        margin-bottom: 0px;
      }
      p {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 1.75;
        font-weight: 400;
        color: #8f8f8f;
        -webkit-font-smoothing: antialiased;
        -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
        text-shadow: rgba(0,0,0,.01) 0 0 1px;
      }
      p a {
        display: inline;
        position: relative;
        color: inherit;
        border-bottom: solid 1px #ffa07f;
        -webkit-transition: all 200ms ease;
        -moz-transition: all 200ms ease;
        -ms-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
      }
      p:last-of-type {
        margin-bottom: 0;
      }
      a, a:hover, a:visited, a:active, a:link {
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
        text-shadow: rgba(0,0,0,.01) 0 0 1px;
      }
      p a:active {
        position: relative;
        color: #FF6347;
      }
      p a:hover {
        color: #FFFFFF;
        background: #ffa07f;
      }
      p a:hover::after {
        opacity: 0.2;
      }
      ::selection {
        background: rgba(187,228,50,0.75);
        color: #FFFFFF;
      } 
      p::selection
      {
        
      }
      h1{font-size: 48px;}
      h2{font-size: 36px;}
      h3{font-size: 24px;}
      h4{font-size: 18px;}
      h5{font-size: 14px;}
      h1, h2, h3, h4, h5, h6
      {
        font-family: 'Open Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
        text-shadow: rgba(0,0,0,.01) 0 0 1px;
      }
      h1::selection, 
      h2::selection, 
      h3::selection, 
      h4::selection, 
      h5::selection, 
      h6::selection
      hr {
        display: block;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: auto;
        margin-right: auto;
        border-style: inset;
        border-width: 1px;
      }
      {
        
      }
      .form-control {
        color: #db5246;
      }
      section {
        display: block;
        position: relative;
        box-sizing: border-box;
      }
      .clear {
        clear: both;
      }
      .clearfix::before, .clearfix::after {
        content: "";
        display: table;
      }
      .clearfix::after {
        clear: both;
      }
      .clearfix {
        zoom: 1;
      }
      .float_left {
        float: left;
      }
      .float_right {
        float: right;
      }
      .trans_200
      {
        -webkit-transition: all 200ms ease;
        -moz-transition: all 200ms ease;
        -ms-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
      }
      .trans_300
      {
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
      }
      .trans_400
      {
        -webkit-transition: all 400ms ease;
        -moz-transition: all 400ms ease;
        -ms-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
      }
      .trans_500
      {
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
      }
    `}
  />
)
export const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 100px;

  @media (max-width: ${breakpoints.l}px){
    margin-top: 96px;
  }
  @media (max-width: ${breakpoints.m}px){
    margin-top: 72px;
  }
  @media (max-width: ${breakpoints.s}px){
    margin-top: 50px;
  }
`
export const CategoryTitle = styled.div`
  text-align: center;
  & h1 {
    font-family: 'Fjalla One', sans-serif;
    text-transform: uppercase;
  }
  @media (max-width: ${breakpoints.s}px){
    & h1 {
      font-size: 40px;
    }
  }
  @media (max-width: ${breakpoints.xs}px){
    & h1 {
      font-size: 32px;
    }
  }
`
export const ProductTitle = styled.div`
  text-align: center;
  & h1 {
    font-family: 'Fjalla One', sans-serif;
    text-transform: uppercase;
  }
  @media (max-width: ${breakpoints.s}px){
    & h1 {
      font-size: 40px;
    }
  }
  @media (max-width: ${breakpoints.xs}px){
    & h1 {
      font-size: 32px;
    }
  }
`
export const Img = styled(Image)`
  max-width: 100 %;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`
export const SectionContainer = styled.div`
  padding-left: 109px;
  padding-right: 107px;
  margin-bottom: 0;

  & .container {
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.xl}px){
    & .container {
      max-width: 960px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: ${breakpoints.l}px){
	padding-left: 0px;
    padding-right: 0px;
    & .container {
      max-width: 720px;
    }
  }

  @media (max-width: ${breakpoints.m}px){
    & .container {
      max-width: 540px;
    }
  }

  @media (max-width: ${breakpoints.s}px){
    & .container {
      max-width: 100%;
    }
  }
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: "left . right";

  @media (max-width: ${breakpoints.l}px){
    display: block;
	  padding-top: 50px;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
  padding: 0 20px;

  @media (max-width: ${breakpoints.xs}px){
    padding: 0 5px;
  }
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px){
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const Banner = styled.div`
  width: 100%;
`
export const BannerContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: 90%;

`

export const ProductsContainer = styled.div`
  width: 100%;
  margin-top: 95px;
  padding-bottom: 99px;
  & p {
    float: right;
    font-size: 18px;
    margin-top: 10px;
    margin-right: 10%;
    color: #000
  }
  @media (max-width: ${breakpoints.m}px){
    margin-top: 40px;
  }
`
